@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
.App {
  text-align: center;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  font-family: 'Open Sans', sans-serif;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .pts-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:space-between;
  color: white;
  scroll-snap-align: start;
  scroll-snap-destination: 100px 0%;
  background-image: url('./images/bg-white-min.jpg');
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.App-main {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  scroll-snap-align: center;
  font-family: 'Open Sans', sans-serif;
  background-image: url('./images/bg2.jpg');
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.App-footer {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  scroll-snap-align: center;
  font-family: 'Open Sans', sans-serif;
  background-image: url('./images/bg3.jpg');
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.App-link {
  color: #61dafb;
}
.MuiTypography-body1,
.MuiTypography-h5 {
    font-family: 'Open Sans', sans-serif !important
}
.MuiTypography-colorTextSecondary {
    color: #779ebd !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row {
    display: flex;
    align-items:center;
    justify-content: flex-end;
    width:100%;
}
.heading {
    font-size:5rem;
    color:#000000;
    font-family: 'Open Sans', sans-serif;
}
.subheading {
    font-size:2rem;
    color:#000000;
    font-family: 'Open Sans', sans-serif;
    margin:0;
}
.p-m {
    margin-bottom: 60px;
}

.ats-sketch {
    width: 250px;
    padding:10px 20px;
}

.faicon {
  display:block;
  color:#000000;
  margin: 10px 30px;
  overflow:hidden;
  text-decoration:none;
  font-size:3rem;
}
.fablack {
    color:#ffffff;
    font-size:5rem;
}

.socialmedia {
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
}
.sm-header {
    margin-bottom: 60px;
    justify-content: flex-end;
    width:100%;
}
.apartments {
    display: flex;
    flex-wrap:nowrap;
    align-items: center;
    justify-content: space-around;
    width:100%;
    z-index: 1000;
}

.link {
    text-decoration:none;
}

.pts-logo {
    height: 15vmin;
    pointer-events: none;
}
.pts-container {
    width:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.addresses {
    font-size: 1.3rem;
    font-weight: 500;
    text-align:left;
    line-height: 2rem;
    padding: 20px 10px;
    color:#000000;
    z-index: 1000;
}
.layer {
  background-color: rgba(102, 102, 102, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 800;
}
.text-main {
    padding:20px;
    font-size:1.6rem;
    font-weight:600;
    color: #000000;
    width: 50%;
    text-shadow: 1px 1px 4px #fff;
    z-index: 1000;
}
.stone-apt { height: 30vmin; }
.brHandler { display: none; }
.logo-container {
  display:flex;
  align-items:center;
  justify-content:center;
  width: 20vmin;
  height: 20vmin;
  overflow:hidden;
}
.container__image {
  position:relative;
  display:flex;
  flex-direction:column;
  justify-content:center;
  width: 15vmin;
  height: 15vmin;
}
.container__image:hover {
  transform:rotate(45deg);
}


@media (max-width:600px) {
    .App-header,
    .App-footer,
    .App-main {
        justify-content:center;
    }
    .addresses { font-size: 0.9rem; padding: 20px 15px; text-align:center; flex: 0 90%; }
    .row { align-items:center; flex-wrap:wrap; justify-content: center; }
    .heading { font-size:3rem; }
    .subheading { font-size:1.5rem; }
    .ats-sketch { width: 140px; padding:5px 20px; }
    .socialmedia { margin: 20px 0px; }
    .socialmedia > div { flex: 0 50%; }
    .sm-item { margin: 10px 0px; }
    .text { padding: 5px 10px; }
    .text { font-size:0.9rem; }
    .fablack { font-size:3rem; }
    .sm-header { justify-content: center; }
    .text-main { padding:0px; font-size:1rem; width: 90%; }
    .brHandler { display: block; }
}
